import Fit from "./fit";
import Video from "./video";
import Canvas from "./canvas";

const Ajax = {
  init: () => {
    var href = null;
    var html = null;

    $(".js-canvasLink").click(function () {
      href = $(this).attr("href");
      Canvas.emptyCanvas();
      Ajax.changePage(href, html);
      return false;
    });
  },

  changePage: (href, html) => {
    var ajax = $.get(href, function (data) {
      html = $(data).find(".js-index").html();
      $(".js-canvas").attr("class", $(data).find(".js-canvas").attr("class"));
      return true;
    }).done(function () {
      Ajax.transitionOut();
      setTimeout(function () {
        if (html) $(".js-index").empty().append(html);
        Ajax.initJS();
        Ajax.changeState(href, false);
        Ajax.transitionIn();
        Canvas.initMouseEnter();
        html = null;
      }, 500);
    });
  },

  changeState: (href, replace) => {
    if (window.location.href !== href) {
      var location = href;
      if (replace && history.replaceState) {
        history.replaceState(
          {
            url: location,
          },
          null,
          location
        );
      } else if (history.pushState) {
        history.pushState(
          {
            url: location,
          },
          null,
          location
        );
      }
    }

    return true;
  },

  initJS: () => {
    Ajax.init();
    Fit.init();
    Video.init();
  },

  transitionIn: () => {
    $(".js-index").addClass("index--in");
  },

  transitionOut: () => {
    $(".js-index").removeClass("index--in");
    $("html, body").animate({ scrollTop: 0 }, 250);
  },

  indexLink: () => {
    $(".js-canvasIndexLink").click(function () {
      if ($(this)[0].pathname != window.location.pathname || $(this).hasClass("js-canvasStructureLink")) {
        Canvas.emptyCanvas();
        Ajax.transitionOut();
        Ajax.changePage($(this).attr("href"), null);
      }
      $(".js-canvasVideo").remove();
      return false;
    });
  },
};

export default Ajax;
