import * as Matter from "matter-js";
import Ajax from "./ajax";

const Canvas = {
  init: () => {
    var height = $(".js-canvas").height();
    var width = $(".js-canvas").width();

    var Engine = Matter.Engine,
      Render = Matter.Render,
      Runner = Matter.Runner,
      Composites = Matter.Composites,
      Common = Matter.Common,
      MouseConstraint = Matter.MouseConstraint;

    Canvas.Bodies = Matter.Bodies;
    Canvas.World = Matter.World;

    var engine = Engine.create();
    Canvas.world = engine.world;

    var render = Render.create({
      element: document.querySelector(".js-canvas"),
      engine: engine,
      options: {
        width: width,
        height: height,
        wireframes: false,
        background: "transparent",
      },
    });

    Render.run(render);
    var runner = Runner.create();
    Runner.run(runner, engine);

    Canvas.bottomWall = Canvas.Bodies.rectangle(width / 2, height + 25, width, 50, {
      isStatic: true,
      render: {
        fillStyle: "transparent",
      },
    });
    Canvas.sideWalls = [
      Canvas.Bodies.rectangle(-25, 0, 50, height * 2, {
        isStatic: true,
        render: {
          fillStyle: "transparent",
        },
      }),
      Canvas.Bodies.rectangle(width + 25, 0, 50, height * 2, {
        isStatic: true,
        render: {
          fillStyle: "transparent",
        },
      }),
    ];

    Canvas.boxes = [];

    Canvas.World.add(Canvas.world, Canvas.sideWalls);
    Canvas.World.add(Canvas.world, Canvas.bottomWall);

    Canvas.initMouseEnter();

    $(".js-canvasClear").click(function () {
      Canvas.emptyCanvas();
      $(".js-indexItem").removeClass("active");
    });

    Render.lookAt(render, {
      min: { x: 0, y: 0 },
      max: { x: width, y: height },
    });

    $(window).resize(function () {
      render.canvas.width = $(".js-canvas").width();
      render.canvas.height = $(".js-canvas").height();
    });
  },

  emptyCanvas: () => {
    Canvas.boxes.forEach((box) => (box.density = 1));
    Canvas.World.remove(Canvas.world, Canvas.bottomWall);
    $(".js-canvasClear").addClass("hidden");
    setTimeout(function () {
      Canvas.World.add(Canvas.world, Canvas.bottomWall);
      Canvas.boxes = [];
    }, 1500);
  },

  addHomeBlocks: (number) => {
    var totalWidths = 0;
    var height = $(".js-canvas").height();
    var width = $(".js-canvas").width();
    var classBlock = number == 0 ? ".js-canvasHomeA" : ".js-canvasHomeB";

    $(classBlock)
      .find(".js-canvasImage")
      .each(function (i, el) {
        var src = $(el).attr("src");
        var ratio = $(el).data("ratio");
        var imgWidth = $(el).data("width");
        var imgHeight = $(el).data("height");
        if (width / height > 1.3) {
          var h = $(el).hasClass("first__block") ? height * 0.4 : height * 0.15;
        } else {
          var h = $(el).hasClass("first__block") ? height * 0.2 : height * 0.1;
        }
        var w = h * ratio;
        var xScale = w / imgWidth;
        var yScale = h / imgHeight;
        var angle = Math.random() * 0.25;
        angle = Math.random() < 0.5 ? -1 * angle : angle;

        var min = w / 2 + 10;
        var max = width - min;
        if (totalWidths + min > max || $(el).hasClass("first")) {
          var wCorrected = Math.random() * (min + 200 - min) + min;
          totalWidths = wCorrected;
        } else {
          var wCorrected = Math.random() * (w + 100 - w - 100) + w - 100;
          totalWidths += w;
        }

        var position = totalWidths;
        var box = Canvas.Bodies.rectangle(position, -height, w, h, {
          restitution: 0.6,
          density: 0.1,
          angle: Math.PI * angle,
          render: {
            strokeStyle: "#000",
            sprite: {
              texture: src,
              xScale: xScale,
              yScale: yScale,
            },
          },
        });

        Canvas.boxes.push(box);

        setTimeout(function () {
          Canvas.World.add(Canvas.world, box);
        }, i * 200);
      });
  },

  addIndexBlocks: (el) => {
    var height = $(".js-canvas").height();
    var width = $(".js-canvas").width();

    $(el)
      .find(".js-indexImages")
      .children()
      .each(function (i, el) {
        var src = $(el).attr("src");
        var ratio = $(el).data("ratio");
        var imgWidth = $(el).data("width");
        var imgHeight = $(el).data("height");
        var h = imgWidth > imgHeight ? height * 0.18 : height * 0.3;
        var w = h * ratio;

        var xScale = w / imgWidth;
        var yScale = h / imgHeight;
        var angle = Math.random() * 0.25;
        angle = Math.random() < 0.5 ? -1 * angle : angle;

        var min = w / 2 + 100;
        var max = width - min;
        if (Canvas.totalWidths - min < 0) {
          var wCorrected = Math.random() * (max - (max - 100)) + max - 100;
          Canvas.totalWidths = wCorrected;
        } else {
          var wCorrected = Math.random() * (w + 100 - w - 100) + w - 100;
          Canvas.totalWidths -= w;
        }

        var position = Canvas.totalWidths;
        var box = Canvas.Bodies.rectangle(position, -height * 0.4, w, h, {
          density: 0.1,
          restitution: 0.6,
          angle: Math.PI * angle,
          render: {
            strokeStyle: "#000",
            sprite: {
              texture: src,
              xScale: xScale,
              yScale: yScale,
            },
          },
        });

        Canvas.boxes.push(box);

        setTimeout(function () {
          Canvas.World.add(Canvas.world, box);
        }, i * 100);
      });
  },

  addWorkBlocks: () => {
    var height = $(".js-canvas").height();
    var width = $(".js-canvas").width();
    var totalWidths = width;

    $(".js-indexImages")
      .children()
      .each(function (i, el) {
        var src = $(el).attr("src");
        var ratio = $(el).data("ratio");
        var imgWidth = $(el).data("width");
        var imgHeight = $(el).data("height");
        if (width / height > 1.3) {
          var h = height * 0.5;
        } else {
          var h = height * 0.25;
        }

        var w = h * ratio;
        var xScale = w / imgWidth;
        var yScale = h / imgHeight;

        var min = w / 2 + 200;
        var max = width - min;
        if (totalWidths - min < 200) {
          var wCorrected = Math.random() * (min + 200 - min) + min;
          totalWidths = wCorrected;
        } else {
          var wCorrected = Math.random() * (w + 100 - w - 100) + w - 100;
          totalWidths -= w;
        }

        var position = totalWidths;
        var box = Canvas.Bodies.rectangle(position, -height * 0.5, w, h, {
          restitution: 0.9,
          render: {
            strokeStyle: "#000",
            sprite: {
              texture: src,
              xScale: xScale,
              yScale: yScale,
            },
          },
        });

        Canvas.boxes.push(box);

        setTimeout(function () {
          Canvas.World.add(Canvas.world, box);
        }, i * 200);
      });
  },

  initMouseEnter: () => {
    if ($(".js-canvas").hasClass("js-canvasHome")) {
      if ($(".js-canvas").hasClass("one")) {
        Canvas.addHomeBlocks(0);
      } else {
        Canvas.addHomeBlocks(1);
      }
      $(".js-canvasHome").on("click", function () {
        if ($(this).hasClass("one")) {
          $(this).removeClass("one").addClass("two");
          Canvas.emptyCanvas();
          setTimeout(function () {
            Canvas.addHomeBlocks(1);
          }, 1500);
        } else if ($(this).hasClass("two")) {
          $(this).removeClass("two").addClass("three");
          var href = $(this).data("href");
          Canvas.emptyCanvas();
          Ajax.transitionOut();
          $(".js-canvasContent").remove();
          setTimeout(function () {
            Ajax.changePage(href, null);
          }, 1000);
        }
      });
    } else if ($(".js-canvas").hasClass("js-canvasWorks")) {
      Canvas.addWorkBlocks();
    } else {
      Canvas.totalWidths = $(".js-canvas").width();
      $(".js-indexItem").mouseenter(function () {
        if ($("html").hasClass("no-touch")) {
          $(".js-canvasClear").removeClass("hidden");
          if (!$(this).hasClass("active")) {
            Canvas.addIndexBlocks($(this));
            $(this).addClass("active");
          }
        }
      });
    }
  },
};

export default Canvas;
