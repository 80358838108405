const Video = {
  init: () => {
    $(".js-embedButton")
      .off("click")
      .on("click", function (e) {
        e.preventDefault();
        if ($(".js-canvasVideo").length) {
          $(".js-canvasVideo").remove();
        } else {
          const href = $(this).attr("href");
          $(".js-canvasContainer").append(`<div class="canvas__video js-canvasVideo"><iframe src="${href}"></iframe></div>`);
          $("html, body").animate({ scrollTop: 0 }, 250);
        }
      });
  },
};

export default Video;
