const Online = {
  init: () => {
    $(".js-onlineLink").on("click", function () {
      $(this).toggleClass("inactive");
      $(".js-onlineContainer")
        .toggleClass("inactive")
        .find("iframe")
        .each(function (i, el) {
          $(el).attr("src", $(el).attr("src"));
        });
    });
  },
};

export default Online;
