const Fit = {
  init: () => {
    $(".js-fit").each(function (i, el) {
      Fit.resize(el);
    });
    $(window).resize(function () {
      $(".js-fit").each(function (i, el) {
        Fit.resize(el);
      });
    });
  },

  resize: (el) => {
    var height = $(el).height();
    $(el).css({
      "--capital-height": height,
    });

    if ($(el).children().first().width() > $(el).width()) {
      Fit.scrollInside(el);
    }
  },

  scrollInside: (el) => {
    $(el)
      .mouseenter(function () {
        var duration = $(el).children().first().width() * 2;
        $(el).animate({ scrollLeft: $(el).children().first().width() }, duration);
      })
      .mouseleave(function () {
        $(el).stop().scrollLeft(0);
      });
  },
};

export default Fit;
