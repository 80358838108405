import "./_lib/modernizr";
import "../scss/application.scss";

import "nodelist-foreach-polyfill";
import "mdn-polyfills/Array.prototype.forEach";
import "mdn-polyfills/Array.prototype.find";
import "mdn-polyfills/Array.prototype.includes";
import "mdn-polyfills/String.prototype.includes";
import lazySizes from "lazysizes";
import jquery from "jquery";

window.$ = window.jQuery = jquery;

import Website from "./_modules/website";
import Home from "./_modules/home";
import Ajax from "./_modules/ajax";
import Canvas from "./_modules/canvas";
import Container from "./_modules/container";
import Online from "./_modules/online";

$(function () {
  Website.init();
  Home.init();
  Container.init();
  Online.init();
  Canvas.init();
  Ajax.initJS();
  Ajax.transitionIn();
  Ajax.indexLink();
});
