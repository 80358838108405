const Home = {
  init: () => {
    $(".js-headerItem").click(function (e) {
      e.preventDefault();
      var $this = $(this);
      var id = $this.attr("href");
      var containerText = $(id).find(".js-toggleText");
      var containerTitle = $(id).find(".js-toggleTitle");
      $(".js-toggleText").not(containerText).slideUp(0);
      $(".js-toggleTitle").not(containerTitle).removeClass("active");
      $(containerText).slideDown(200);
      $(containerTitle).addClass("active");
      $("html, body").animate({ scrollTop: $(id).offset().top - 51 }, 250);
    });

    $(".js-headerItemHome").click(function () {
      $("html, body").animate({ scrollTop: 0 }, 250);
    });
  },
};

export default Home;
